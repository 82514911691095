
import { computed, defineComponent, reactive } from "vue";
import { useStore } from "vuex";
import { useHead } from "@vueuse/head";
import { useRoute, useRouter } from "vue-router";

import CFormInputText from "@/components/form/InputText.vue";
import CFormInputNumber from "@/components/form/InputNumber.vue";
import CFormSelect from "@/components/form/Select.vue";
import PCalendar from "primevue/calendar";

import { guideStatus, formatDateEn, useFilter } from "@/libs/utils";

const VGuideFilter = defineComponent({
  name: "VGuideFilter",
  components: { CFormInputText, CFormSelect, PCalendar, CFormInputNumber },
  setup() {
    useHead({ title: "Filtrar guias eletrônicas | obmed" });

    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const { hasAnyFilters } = useFilter();

    const establishments = computed(() => store.state.establishment.all);
    const filters = reactive({
      cd_guia_eletronica_id: route.query.cd_guia_eletronica_id || undefined,
      nm_paciente: route.query.nm_paciente || undefined,
      nm_especialista: route.query.nm_especialista || undefined,
      nm_procedimento: route.query.nm_procedimento || undefined,
      dt_inicial: route.query.dt_inicial ? new Date(String(route.query.dt_inicial).replaceAll("-", "/")) : undefined,
      dt_final: route.query.dt_final ? new Date(String(route.query.dt_final).replaceAll("-", "/")) : undefined,
      ie_status: Number(route.query.ie_status) || undefined,
      ie_tipo_agenda: route.query.ie_tipo_agenda || undefined,
      cd_estabelecimento: route.query.cd_estabelecimento || undefined,
    });
    const loading = reactive({ submit: false });
    const agendaType = [
      { label: "Consulta", value: "C" },
      { label: "Exame", value: "E" },
    ];

    async function applyFilters() {
      const dt_inicial = filters.dt_inicial ? formatDateEn(String(filters.dt_inicial)) : undefined;
      const dt_final = filters.dt_final ? formatDateEn(String(filters.dt_final)) : undefined;

      router.replace({ query: { ...route.query, ...filters, dt_inicial, dt_final } });

      loading.submit = true;
      await store.dispatch("searchGuides", {
        search: { ...filters, dt_inicial, dt_final },
        isPrimary: true,
      });
      loading.submit = false;
    }

    function resetFilters() {
      filters.cd_guia_eletronica_id = undefined;
      filters.nm_paciente = undefined;
      filters.nm_especialista = undefined;
      filters.nm_procedimento = undefined;
      filters.dt_inicial = undefined;
      filters.dt_final = undefined;
      filters.ie_status = undefined;
      filters.ie_tipo_agenda = undefined;

      applyFilters();
    }

    function handleClose() {
      router.replace({ name: "guide-list", query: route.query });
    }

    if (!establishments.value.length) store.dispatch("getAllEstablishment");
    if (hasAnyFilters.value) applyFilters();

    return {
      filters,
      establishments,
      loading,
      hasAnyFilters,
      applyFilters,
      resetFilters,
      handleClose,
      guideStatus,
      agendaType,
    };
  },
});

export default VGuideFilter;
